<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <div class="dialog-content">
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <div class="form-control">
                            <md-autocomplete v-model="deptName" @md-selected="getDeptSelected" :md-options="depts" @md-changed="getDepts"  @md-opened="getDepts" :class="{'md-invalid': submitted && $v.entity.departmentId.$error }">
                                <label>Phòng ban</label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                                <span class="md-error" v-if="submitted && !$v.entity.departmentId.isSelected">Vui lòng chọn phòng ban</span>
                            </md-autocomplete>
                            <md-button @click="openDept()" class="md-icon-button">
                                <md-icon>manage_search</md-icon>
                            </md-button>
                        </div>
                    </div>   
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="position">Chức vụ</label>
                            <md-input name="position" v-model="entity.position" ></md-input>
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-datepicker v-model="entity.dateOfBirth" :md-model-type="String">
                            <label>Ngày sinh</label>
                        </md-datepicker>
                    </div>
                </div>
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <md-field :class="{'md-invalid': submitted && $v.entity.code.$error }">
                            <label for="code">Mã nhân viên</label>
                            <md-input name="code" v-model="entity.code"></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.code.required">Vui lòng nhập </span>
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field :class="{'md-invalid': submitted && $v.entity.firstName.$error }">
                            <label for="first-name">Họ và tên đệm</label>
                            <md-input name="first-name" v-model="entity.firstName" ></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.firstName.required">Vui lòng nhập họ và tên đệm</span>
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="phone">Số điện thoại</label>
                            <md-input name="phone" v-model="entity.phone" ></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <div class="form-control">
                            <md-autocomplete v-model="leadName" @md-selected="getLeadSelected" :md-options="leads" @md-changed="getLeads"  @md-opened="getLeads">
                                <label>Lead</label>
                                <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                    <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                </template>
                                <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                    "{{ term }}" Không tìm thấy!
                                </template>
                            </md-autocomplete>
                            <md-button @click="openLead()" class="md-icon-button">
                                <md-icon>manage_search</md-icon>
                            </md-button>
                        </div>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field :class="{'md-invalid': submitted && $v.entity.lastName.$error }">
                            <label for="last-name">Tên</label>
                            <md-input name="last-name" v-model="entity.lastName" ></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.lastName.required">Vui lòng nhập tên</span>
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="email">Email</label>
                            <md-input name="email" v-model="entity.email" ></md-input>
                        </md-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="address">Địa chỉ</label>
                            <md-input name="address" v-model="entity.address" ></md-input>
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="identityNumber">CMTND</label>
                            <md-input name="identityNumber" v-model="entity.identityNumber" ></md-input>
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <div class="radio-group">
                            <label>Hoạt động/khóa</label>
                            <div class="form-control ">
                                <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="bankAccount">Tên tài khoản</label>
                            <md-input name="bankAccount" v-model="entity.bankAccount"></md-input>
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="bankNumber">Số tài khoản</label>
                            <md-input name="bankNumber" v-model="entity.bankNumber"></md-input>
                        </md-field>
                    </div>
                    <div class="col l-4 m-4 c-12">
                        <md-field>
                            <label for="bankName">Ngân hàng</label>
                            <md-input name="bankName" v-model="entity.bankName"></md-input>
                        </md-field>
                    </div>
                </div>
            </div>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <deptList ref="deptList" @close="closeDept"/>
        <leadList ref="leadList" @close="closeLead"/>
    </md-dialog>
</template>
<script>
    import staffService from '../../../api/staffService';
    import departmentService from '../../../api/departmentService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import deptList from '../../../components/popup/_DepartmentList.vue'
    import leadList from '../../../components/popup/_StaffList.vue';

    export default ({
        components: {
            deptList,
            leadList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật nhân viên'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               saveAndCreate: false,
               entity: { id: 0, bankAccount: '', bankNumber: '', bankName: '', leadId: null, code: '', departmentId: 0, firstName: '', lastName: '', address: '', phone: '', email: '', position: '', identityNumber: '', dateOfBirth: null, isDeleted: false, isActive: true },
               depts: [],
               deptName: '',
               leads: [],
               leadName: '',
               showDialog: false
            }
        },
        created(){
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới thông tin nhân viên';
                    this.deptName = '';
                    this.leadName = '';
                    this.entity = { id: 0, bankAccount: '', bankNumber: '', bankName: '', leadId: null, code: '', departmentId: 0, firstName: '', lastName: '', address: '', phone: '', email: '', position: '', identityNumber: '', dateOfBirth: null, isDeleted: false, isActive: true };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật thông tin nhân viên';
                    this.deptName = '';
                    this.leadName = '';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
                this.setLoading(false);
            },

            //Lead
            closeLead(item){
                this.leadName = item.fullName;
                this.entity.leadId = item.id;
                this.$refs.leadList.close();
            },

            getLeadSelected(val){
                this.entity.leadId = val.id;
                this.leadName = val.fullName;
            },

            openLead(){
                this.$refs.leadList.open();
            },

            getLeads(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.leads = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            closeDept(item){
                this.deptName = item.name;
                this.entity.departmentId = item.id;
                this.$refs.deptList.close();
            },

            getDeptSelected(val){
                this.entity.departmentId = val.id;
                this.deptName = val.name;
            },

            openDept(){
                this.$refs.deptList.open();
            },

            getDepts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, keyword:  searchTerm };
                departmentService.getDepartments(search).then((response) => {
                    if(response.statusCode == 200){
                        this.depts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.entity.$touch();
                if (this.$v.entity.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                staffService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới nhân viên thành công");
                        this.$emit('close');
                        if(!this.saveAndCreate){
                            this.showDialog = false;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                staffService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                staffService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.deptName = this.entity.department.name;
                        if(this.entity.leadId > 0){
                            this.leadName = this.entity.lead.fullName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: { 
            deptName: function (val) { 
                if(val == ''){
                    this.entity.departmentId = 0;
                }
            },
            leadName: function (val) { 
                if(val == ''){
                    this.entity.leadId = null;
                }
            }
        },
        validations: {
            entity: {
                code: { required },
                departmentId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                firstName: { required },
                lastName: { required },
            }
        }
    })

</script>


<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 1000px;
        height: 460px;
        max-width: 1000px;
    }
    .md-scrollbar {
        height: 580px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>